<template>
  <div class="maxBox">
    <div>
      <van-sticky>
        <van-field v-model="dataform.OrganName"
          is-link
          readonly
          placeholder="请选择所在地区"
          @click="show = true" />
        <!-- <van-popup v-model="show"
        position="bottom">
        <van-picker show-toolbar
          :columns="orgonList"
          value-key="organname"
          @confirm="ocodeQr"
          @cancel="show = false" />
      </van-popup> -->
        <van-popup v-model="show"
          round
          position="bottom">
          <van-cascader v-model="cascaderValue"
            title="请选择所在地区"
            :options="orgonList"
            @close="show = false"
            :field-names="{
              text: 'OName',
              value: 'OCode',
              children: 'Children',
            }"
            @finish="onFinish" />
        </van-popup>
      </van-sticky>
    </div>
    <!-- 轮播图 -->
    <div class="header">
      <div class="personal">

        <van-swipe class="homebanner"
          :autoplay="3000"
          indicator-color="white">
          <van-swipe-item style="margin:auto;width:95%;"><img src="https://lycaljdapi.dexian.ren//upload/images/2021/12/2cf1991b-638.png" /></van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="gridMaxClass">
      <div class="gridClass">
        <van-grid :border="false"
          :column-num="3">
          <van-grid-item @click="zhucan('1')">
            <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/5912718d-442.png"
              style="width:50px;" />
            <span class="palace">助餐</span>
          </van-grid-item>
          <!-- <van-grid-item icon="photo-o"
            @click="zhucan('4')">
            <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/6f010a0b-b43.png"
              style="width:50px;" />
            <span class="palace">助浴</span>
          </van-grid-item> -->
          <van-grid-item @click="zhucan('2')">
            <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/afb6f88a-d5c.png"
              style="width:50px;" />
            <span class="palace">助洁</span>
          </van-grid-item>
          <van-grid-item icon="photo-o"
            @click="zhucan('3')">
            <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/2aa400de-a75.png"
              style="width:50px;" />
            <span class="palace">助行</span>
          </van-grid-item>

          <van-grid-item icon="photo-o"
            @click="zhucan('5')">
            <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/ebe994a8-768.png"
              style="width:50px;" />
            <span class="palace">助医</span>
          </van-grid-item>
          <van-grid-item icon="photo-o"
            @click="zhucan('7')">
            <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/aedbac8e-1bb.png"
              style="width:50px;" />
            <span class="palace">巡访关爱</span>
          </van-grid-item>
          <!-- <van-grid-item icon="photo-o"
            @click="zhucan('6')">
            <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/af5fc0a5-3c4.png"
              style="width:50px;" />
            <span class="palace">康复</span>
          </van-grid-item>
          <van-grid-item icon="photo-o"
            @click="zhucan('8')">
            <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/d77e5880-570.png"
              style="width:50px;" />
            <span class="palace">辅助租赁</span>
          </van-grid-item> -->
          <van-grid-item icon="photo-o"
            @click="zhucan('99')">
            <van-image src="https://lycaljdapi.dexian.ren//upload/images/2021/12/388f4f57-864.png"
              style="width:50px;" />
            <span class="palace">其他</span>
          </van-grid-item>
        </van-grid>
      </div>

    </div>
    <!-- <div style="margin:10px 0;">
      <van-notice-bar color="black"
        background="#FAFAFA"
        mode="link"
        style="padding:10px 10px 10px 0;">
        <div class="tost">信息通知</div><span style="line-height: 40px;">技术是开发它的人的共同灵魂。</span>
      </van-notice-bar>
    </div> -->
    <!-- <div class="packages">
      <div class="packagesName">养老服务包</div>
      <div style="border-radius: 5px;padding: 8px;background: white;">
        <van-swipe class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
          :show-indicators="false"
          style="margin:10px;">
          <van-swipe-item class="homebanner"
            v-for="item in ServiceList"
            :key="item.SId">
            <router-link :to="'/ylOrder/ylOrder/orderData/' + item.SId"
              style="color:black;">
              <div style="float:left;width:40%;">
                <img :src='item.ThemeImgUrl' />
              </div>

              <div style="float:left;margin-left:5px;">
                <span style="line-height:30px;">服务等级：{{item.DemandLevelDesc}}<br />
                  <span v-if="item.ServiceTime != null">服务时长：{{item.SerDuration}}<br /></span>
                  <span v-else>服务时长：暂无<br /></span>
                  收费标准：{{item.Charges}}</span>

              </div>

              <div style="float: left;width: 100%;"
                class="van-ellipsis">{{item.SGNames}}</div>
            </router-link>

          </van-swipe-item>
        </van-swipe>
      </div>

    </div> -->
    <div class="gridMaxClass"
      v-if="jigouShow"
      style="padding: 5px 0;">
      <div class="grouptitle">
        <h3 style="margin:5px 0;">养老服务机构</h3>
      </div>
      <div>
        <div class="information">
          <div class="center"
            style="padding:0;"
            v-for="item in SerOrganList"
            :key="item.index">
            <div @click="hotelClick('/ylOrder/serPackage/' + item.SOId + '/' + item.OrgCode)">
              <van-card style="padding: 10px 0px;background-color: white;">
                <template #thumb>
                  <div class="van-card__thumb">
                    <img style="width:100%;height:100%;"
                      src="https://lyxnyapi.dexian.ren//upload/images/2022/9/1e41b2a6-bdd.png" />
                    <!-- <img v-else
                      style="width:100%;height:100%;"
                      :src="item.ImgUrls.split(',')[0]" /> -->
                  </div>

                </template>
                <template #title>
                  <div class="centerTitle van-ellipsis--l1"
                    style="width: 75%;line-height: 30px;">{{ item.Name }}</div>
                </template>

                <template #tags>
                  <div style="font-size: 16px;line-height: 30px;">
                    <div>
                      <van-icon name="manager" />{{item.Charger}}</div>
                    <div class="orginBottom">
                      <div>
                        <van-icon name="phone" />{{item.TouchTel}}
                        <!-- <a :href="'tel:' + item.TouchTel"
                          style="color:black;">{{item.TouchTel}}</a> -->
                      </div>
                      {{item.OrgName}}
                    </div>
                  </div>

                </template>
                <!-- <template #num>
                  <div style="padding-right:5px;">
                    {{item.OrgName}}
                  </div>
                </template> -->
              </van-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gridMaxClass"
      v-if="!jigouShow"
      style="padding: 5px 0;">
      <div class="grouptitle">
        <h3 style="margin:5px 0;">养老服务套餐</h3>
      </div>

      <div class="waterfall">
        <div class="list"
          v-for="item in ServiceList"
          :key="item.SId">
          <img :src="item.ThemeImgUrl"
            onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'"
            style="width:100%;"
            @click="rockDetail(item.SPId)" />
          <div style="width:90%;margin:auto;">
            <div style="font-size: 18px;color: #262626;font-weight:550;"
              class="van-ellipsis">{{item.SGNames}}</div>
            <div class="address">
              <div style="margin:3px 0;">
                <span class="tagClass">{{item.DemandLevelDesc}}</span>
                <!-- <span class="tagClass"
                  v-if="item.SerDuration != null && item.SerDuration != ''">{{item.SerDuration}}</span> -->
              </div>
            </div>
            <div class="count van-multi-ellipsis--l3"
              v-if="item.SerDuration != null && item.SerDuration != ''">服务时长：{{item.SerDuration}}</div>
            <div class="money">¥{{item.Cost}}
              <van-button icon="plus"
                size="small"
                style="border-radius: 12px;"
                color="#ffc002"
                type="primary"
                @click="orderPlace(item)">
              </van-button>
            </div>

            <br>
          </div>

        </div>
      </div>
      <!-- <div v-for="item in ServiceList"
        :key="item.SId">
        <div style="float: left;margin:5px 0;border-bottom:1px solid #C6C6C6;">
          <div style="float: left;"
            @click="rockDetail('/ylOrder/ylOrder/orderData/'+ item.SPId)">
            <div style="float:left;width:40%;">
              <img :src="item.ThemeImgUrl"
                onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'"
                style="width:100%;" />
            </div>
            <div style="float:left;margin-left:10px;font-size: 14px;color: #646566;">
              <span style="line-height:30px;">服务等级：{{item.DemandLevelDesc}}<br />
                <span v-if="item.SerDuration != null && item.SerDuration != ''">服务时长：{{item.SerDuration}}<br /></span>
                <span v-else>服务时长：暂无<br /></span>
                收费标准：{{item.Cost}}</span>
            </div>
          </div>

          <div style="float: left;width: 60%;margin: 5px;font-size: 16px;color: rgb(100, 101, 102);"
            class="van-ellipsis">{{item.SGNames}}</div>
          <div style="float: right;margin: 5px;font-size: 16px;">
            <div class="placeOrder"
              @click="orderPlace(item)">我要下单</div>
          </div>
        </div>
      </div> -->
    </div>
    <van-action-sheet v-model="orderPlaceshow"
      title="已选商品">
      <div class="content">
        <div>
          <div v-for="item in orderplaceList"
            :key="item.SId">
            <div class="orderYx">
              <div style="float:left;width: 25%;">
                <img :src="item.SImgUrl"
                  style="width:100%;"
                  class="van-image__img"
                  onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'" />
              </div>

              <div class="orderRight">
                <span style="line-height:25px;">
                  <span style="font-size: 18px;font-weight: 600;"
                    class="van-ellipsis--l1">{{item.SName}}<br /></span>
                  <!-- <span style="font-size: 16px;font-weight: 600;">{{item.SName}}<br /></span> -->
                  <span style="color: red"> <span style="font-size:16px;">￥{{item.SPrice}}</span> {{item.SMUnit}}<br /></span>
                  <span>总价：￥{{item.SAmount}}<br /></span>
                </span>

              </div>
              <!-- <div style="float: left;width: 60%;margin: 5px;font-size: 16px;color: rgb(100, 101, 102);"
                class="van-multi-ellipsis--l2">{{item.SName}}</div> -->
              <div style="padding: 10px;margin-top: -30px;width: 26%;float: right;">
                <van-stepper v-model="item.SNum"
                  theme="round"
                  min="0"
                  style="right: 20px;top: 110px;"
                  button-size="22"
                  disable-input
                  @plus="changeAdd(item)"
                  @minus="changeDelet(item)"
                  @change="change" />
              </div>

            </div>

          </div>
        </div>
        <!-- <div style="">
          <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
            <van-button round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="placeOrder()">
              下单
            </van-button>
          </div>
        </div> -->
        <div style="">
          <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
            <van-button round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="orderRen()">
              下 单
            </van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="orderRenshow"
      title="配送老人"
      @close='close()'>
      <div class="content">

        <van-radio-group v-model="oldManList"
          v-for="item in MyBindInfoList"
          :key="item.BindId"
          style="margin: 10px;padding:15px 10px;background: #f6f7f8;border-radius: 5px;">
          <van-radio :name="item.BindId"
            checked-color="#ffc002"
            style="padding: 20rpx;">
            <div style="font-size: 18px;font-weight: 600;margin-bottom: 10rpx;margin: 5px;">
              {{item.OMName}}
              <span style="font-weight: 500;">{{item.OMMobile}}</span>
              <span style="padding-left: 20px;"
                @click="updata(item)">
                <van-icon name="edit" /></span>
              <span style="padding-left: 20px;"
                @click="add()">
                <van-icon name="add-o" /></span>
            </div>
            <!-- <div style="font-size: 14px;margin-bottom: 10rpx;">{{item.Summary}}</div> -->
            <div style="margin: 5px;">{{item.OMAddr}}</div>
          </van-radio>
        </van-radio-group>
        <div style="">
          <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
            <van-button round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="placeOrder()">
              确定
            </van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="orderRenAddshow"
      title="配送老人信息"
      @close='close()'>
      <div class="content">
        <van-field v-model="oldmanForm.OMIdCard"
          class="shuru"
          name="oldmanForm.OMIdCard"
          label="身份证号"
          placeholder="请输入身份证号" />
        <van-field v-model="oldmanForm.OMMobile"
          class="shuru"
          name="oldmanForm.OMMobile"
          label="联系电话"
          placeholder="请输入老人联系电话" />
        <van-field v-model="oldmanForm.OMAddr"
          type="textarea"
          rows="1"
          autosize
          class="shuru"
          name="oldmanForm.OMAddr"
          label="居住地址"
          placeholder="请输入老人现居住地址" />
        <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
          <van-button round
            block
            type="info"
            color="black"
            @click="submit()">保存</van-button>
        </div>
      </div>
    </van-action-sheet>
    <div class="hidden">没有更多了~</div>

    <van-tabbar v-model="active"
      @change="onChangeTab"
      active-color="#ffc002"
      inactive-color="#000">
      <van-tabbar-item icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="chat-o">订单</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {
  WeGetServiceList,
  WeGetSerPackageList,
  WeGenerateOrder,
  WeSaveOrderPackage,
  WeGetOrderServiceList,
  WeDeleteOrderService,
  WePlaceOrder,
  WeGetMyRegInfo,
  WeGetMyBindInfoPage,
  WeSaveBindInfo,
  WxGetSerOrganCount,
  WxGetSerOrganList,
} from "@/api/ylOrder.js";
import { setOpenId, getOpenId, setOrderId, getOrderId, removeOrderId } from "@/utils/auth";
import { GetChildOrgansNullToken, WeGetOrganTree } from "@/api/Organ";
import Vue from 'vue';
import { Toast } from 'vant';
import wx from "weixin-js-sdk";

Vue.use(Toast);
export default {
  data () {
    return {
      active: 0,
      ServiceList: [],
      show: false,
      jigouShow: false,
      orderPlaceshow: false,
      orderRenshow: false,
      orderRenAddshow: false,
      orgonList: [],
      orderplaceList: [],
      MyBindInfoList: [],
      SerOrganList: [],
      oldManList: "",
      cascaderValue: "",
      dataform: {
        OrgName: '',
        OrgCode: '',
        OrganName: '请选择所在地区',
      },
      oldmanForm: {
        OMIdCard: '',
        OMMobile: '',
        OMAddr: '',
        BindId: undefined
      },
      orderId: "",
      sum: "",
      count: 1,
      sumid: "",
      addOrder: "",
      oCode: undefined,
      SOId: "",
      orginShow: false,
    }
  },
  created () {
    //获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getOrgonTree()
    // this.getUser()
    this.gitocodelist()
    this.getSerOrganCount()
    // this.getServiceList();
    // this.getSerPackageList();
  },
  methods: {
    // 底部导航栏切换
    onChangeTab: function (row) {
      console.log(row);
      if (row == '1') {
        this.$router.push({
          path: "/ylOrder/orderList",
        });
      }
    },
    // 所在社区服务机构数量
    getSerOrganCount () {
      this.orginShow = false
      WxGetSerOrganCount({ openID: getOpenId() }).then((res) => {
        console.log(res.data.count);
        if (res.data.count == '1') {
          this.SOId = res.data.soId
          this.jigouShow = false
          this.getSerOrganList()
          this.getSerPackageList(this.SOId);
        } else {
          this.jigouShow = true
          this.SOId = res.data.soId
          this.getSerOrganList()
        }
      });
    },
    // 社区搜索
    onFinish: function (val) {
      this.show = false;
      this.fieldValue = val.selectedOptions.map((option) => option.OName).join("/");
      this.dataform.OrganName = this.fieldValue;
      this.OCode = val.value
      this.SerOrganList = []
      // this.getSerOrganList()
      this.getSerOrganCountSearch(this.OCode)
    },
    //顶部搜索
    getSerOrganCountSearch (code) {
      this.orginShow = false
      WxGetSerOrganCount({ orgCode: code }).then((res) => {
        console.log(res.data.count);
        if (res.data.count == '1') {
          this.SOId = res.data.soId
          this.jigouShow = false
          this.getSerOrganListSearch(code)

        } else if (res.data.count == '0') {
          this.jigouShow = true
          Toast.loading("该社区暂无服务机构")
          this.orginShow = true
          this.getSerOrganListSearch(code)
        } else {
          this.jigouShow = true
          this.SOId = res.data.soId
          this.getSerOrganListSearch(code)
        }
      });
    },
    // 顶部搜索获取养老服务机构
    getSerOrganListSearch: function (code) {
      WxGetSerOrganList({ orgCode: code }).then((res) => {
        this.loading = false;
        if (res.data.code == '0') {
          let rows = res.data.data; //请求返回当页的列表
          // 加载状态结束

          if (res.data.code == 0) {
            this.SerOrganList = rows
            console.log(this.SerOrganList);
            if (this.SerOrganList.length == '1') {
              this.OrgCode = this.SerOrganList[0].OrgCode
              this.SOId = this.SerOrganList[0].SOId
              this.getSerPackageList(this.SOId);
            } else {
              this.OrgCode = code
            }
          }

        } else {

        }
      });
    },
    // 获取养老服务机构
    getSerOrganList: function () {
      WxGetSerOrganList({ openID: getOpenId() }).then((res) => {
        this.loading = false;
        if (res.data.code == '0') {
          let rows = res.data.data; //请求返回当页的列表
          // 加载状态结束

          if (res.data.code == 0) {
            this.SerOrganList = rows
            console.log(this.SerOrganList);
            if (this.SerOrganList.length == '1') {
              this.OrgCode = this.SerOrganList[0].OrgCode
            }
          }

        } else {

        }
      });
    },
    // 组织机构
    getOrgonTree () {
      WeGetOrganTree().then((res) => {
        this.orgonList = res.data.data;
        this.dataform.OrganName = res.data.data[0].organname
        this.oCode = res.data.data[0].organcode
        this.OrgCode = res.data.data[0].Children[0].OCode
      });
    },
    gitocodelist () {
      WeGetOrganTree().then((res) => {
        this.orgonList = res.data.data;
      })
    },
    getUser () {
      WeGetMyRegInfo({ openID: getOpenId() }).then((res) => {
        if (res.data.code == '0') {
          this.userXq = res.data.data
          this.dataform.OrganName = res.data.data.BindOrgName
          this.dataform.oCode = res.data.data.BindOrgCode
          // this.getOrgonTreeChang()
          this.gitocodelist()
        } else {
          // this.getOrgonTree()
          this.gitocodelist()
        }

      });
    },
    // 绑定时
    getOrgonTreeChang () {
      GetChildOrgansNullToken({ organid: '161' }).then((res) => {
        this.orgonList = res.data.data;
      });
    },
    ocodeQr (val) {
      console.log(val);
      this.oCode = val.organcode;
      this.dataform.OrganName = val.organname;
      // this.hzlist.HouserStatusName = val.Name;
      this.show = false;
    },
    // 地区选择完成后 触发事件
    // onFinish ({ selectedOptions }) {
    //   console.log(selectedOptions);
    //   this.show = false;
    //   this.fieldValue = selectedOptions.map((option) => option.OName).join("/");
    //   this.OCode = selectedOptions.map((option) => option.OCode).join("/");
    //   this.dataform.OrganName = this.fieldValue;
    //   if (selectedOptions[2] == undefined) {
    //     this.dataform.OrgCode = selectedOptions[0].OCode;
    //     this.oCode = this.dataform.OrgCode
    //   } else {
    //     this.dataform.OrgCode = selectedOptions[0].OCode;
    //     console.log(this.dataform.OrgCode);
    //     // this.getSerOrganList()
    //   }
    // },

    getServiceList: function (row) {
      WeGetServiceList().then((res) => {
        this.ServiceList = res.data.data;
      });
    },
    getSerPackageList: function (row) {
      WeGetSerPackageList({ soId: row }).then((res) => {
        this.ServiceList = res.data.data;
        if (this.ServiceList.length == '0') {
          Toast.loading("暂无服务套餐")
        }
      });
    },
    zhucan: function (row) {
      if (this.orginShow) {
        Toast.loading("该社区暂无服务机构")
      } else {
        if (this.SOId == '0') {
          var path = "/ylOrder/serOrganList/" + row + '/' + this.OrgCode
          if (window.__wxjs_environment === "miniprogram") {
            window.wx.miniProgram.navigateTo({
              url: "../public/goto?path=" + path,
            });
          } else {
            this.$router.push(path);
          }
        } else {
          var pathOne = "/ylOrder/currentOrder/" + row + '/' + this.SOId + '/' + this.OrgCode
          if (window.__wxjs_environment === "miniprogram") {
            window.wx.miniProgram.navigateTo({
              url: "../public/goto?path=" + pathOne,
            });
          } else {
            this.$router.push(pathOne);
          }
        }
      }


      // this.$router.push("/ylOrder/currentOrder/" + row + "/" + this.oCode);
    },
    rockDetail: function (SPId) {
      var path = '/ylOrder/ylOrder/orderData/' + SPId + '/' + this.OrgCode
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
      // this.$router.push("/ylOrder/ylOrder/orderData/" + row);
    },
    hotelClick: function (row) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + row,
        });
      } else {
        this.$router.push(row);
      }
      // this.$router.push("/ylOrder/ylOrder/orderData/" + row);
    },
    getMyBindInfoPage: function () {
      WeGetMyBindInfoPage({ openID: getOpenId(), page: '1', limit: '999' }).then((res) => {
        if (res.data.code == '0') {
          if (res.data.data.length != '0') {
            this.MyBindInfoList = res.data.data;
            // this.orderListShow = false
          } else {
            this.MyBindInfoList = []
            this.orderRenshow = false
            this.orderRenAddshow = true

            // Toast.fail('未绑定老人信息');
            // this.orderListShow = true
          }
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    onAdd: function (row) {
      console.log(row);
    },
    close: function () {
      // console.log(123123);
      this.oldManList = ''
      this.oldmanForm.OMIdCard = ''
      this.oldmanForm.OMMobile = ''
      this.oldmanForm.OMAddr = ''
    },
    // 下单人
    orderRen: function (row) {
      this.orderRenshow = true
      this.getMyBindInfoPage()
    },
    // 生成订单
    createOrder: function (row) {
      WeGenerateOrder({
        OType: 2,
        OSerKind: 0,
        SOId: this.SOId,
        OpenID: getOpenId(),
        OrgCode: this.OrgCode
      }).then((res) => {
        if (res.data.code == "0") {
          this.orderId = res.data.data
          this.saveOrderService(row.SPId);
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 下单弹窗
    orderPlace: function (row) {
      console.log(this.orderId);
      this.orderPlaceshow = true
      if (this.sumid != row.SPId) {
        this.count = 1
        this.sum = 1;
      }
      if (this.orderId == '') {
        this.createOrder(row)
      } else {
        this.saveOrderService(row.SPId);
      }

    },
    //保存订单
    saveOrderService: function (row) {
      WeSaveOrderPackage({
        SPId: row,
        OrderId: this.orderId,
        OpenID: getOpenId(),
        SNum: this.sum,
      }).then((res) => {
        if (res.data.code == "0") {
          this.orderPlaceshow = true
          this.getOrderServiceList()
        } else if (res.data.code == "2") {
          console.log(123123);
          this.orderPlaceshow = true
          removeOrderId()
          // this.getOrderServiceList()
        }
        this.getOrderServiceList()
      });
    },
    //已下订单列表
    getOrderServiceList: function () {
      WeGetOrderServiceList({ openID: getOpenId(), orderId: this.orderId }).then(
        (res) => {
          if (res.data.code == '0') {
            this.orderplaceList = res.data.data;
          } else {
            Toast.fail(res.data.msg);
          }
          // this.addOrder = res.data.data[0].OrderId
        }
      );
    },
    //添加数量
    changeAdd: function (row) {
      console.log(row);
      // this.OrderId = row.OrderId
      this.SId = row.SId
    },
    //删除数量
    changeDelet: function (row) {
      // this.OrderId = row.OrderId
      this.OSId = row.OSId
      this.SId = row.SId
    },
    //数量
    change: function (value) {
      console.log(value);
      if (value == '0') {
        this.deleteOrderService()
      } else {

        this.changeOrderService(value)
      }

    },
    // 添加删除后保存
    changeOrderService: function (row) {
      WeSaveOrderPackage({
        SPId: this.SId,
        OrderId: this.orderId,
        OpenID: getOpenId(),
        SNum: row,
      }).then((res) => {
        if (res.data.code == "0") {
          console.log(res.data.data);
          this.changeOrderServiceList()
        }
      });
    },
    // 删除订单服务项目
    deleteOrderService: function () {
      WeDeleteOrderService({
        OSId: this.OSId,
        OrderId: this.orderId,
        OpenID: getOpenId(),
      }).then((res) => {
        if (res.data.code == "0") {
          Toast.fail('已删除');
          this.changeOrderServiceList()
          // this.orderPlaceshow = false;
        }
      });
    },
    changeOrderServiceList: function () {
      WeGetOrderServiceList({ openID: getOpenId(), orderId: this.orderId }).then(
        (res) => {
          if (res.data.data.length != '0') {
            this.orderplaceList = res.data.data;
            this.addOrder = res.data.data[0].OrderId
          } else {
            this.show = false;
            this.orderPlaceshow = false;
          }

        }
      );
    },
    //下单
    placeOrder: function () {
      console.log(this.oldManList);
      if (this.oldManList == '') {
        Toast.fail('请选择需要配送的老人信息');
      } else {
        WePlaceOrder({ OpenID: getOpenId(), OrderId: this.orderId, BindId: this.oldManList }).then(
          (res) => {
            if (res.data.code == '0') {
              Toast.success('下单成功');
              removeOrderId()
              this.count = ''
              this.sum = ''
              this.sumid = ''
              this.orderId = ''
              this.orderplaceList = []
              this.show = false;
              this.orderPlaceshow = false;
              this.orderRenshow = false
            } else {
              Toast.fail(res.data.msg);
            }
          }
        );
      }
    },
    // 添加老人信息
    submit () {
      console.log(this.oldmanForm);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!reg.test(this.oldmanForm.OMIdCard)) {
        Toast.fail("身份证号码不正确");
      } else if (this.oldmanForm.OMMobile == '') {
        Toast.fail("请输入老人联系电话");
      } else if (this.oldmanForm.OMAddr == '') {
        Toast.fail("请输入老人现居住地址");
      } else {
        WeSaveBindInfo(
          {
            OpenID: getOpenId(),
            OMIdCard: this.oldmanForm.OMIdCard,
            OMMobile: this.oldmanForm.OMMobile,
            OMAddr: this.oldmanForm.OMAddr,
            BindId: this.oldmanForm.BindId,
          }
        ).then((res) => {
          if (res.data.code == '0') {
            Toast.success('绑定成功');
            this.orderRenAddshow = false
            this.orderRenshow = true
            this.oldmanForm.OMIdCard = ''
            this.oldmanForm.OMMobile = ''
            this.oldmanForm.OMAddr = ''
            this.oldmanForm.BindId = undefined
            this.getMyBindInfoPage()
          } else {
            Toast.fail(res.data.msg);
          }

        });
      }
    },
    add: function () {
      this.orderRenAddshow = true
    },
    // 编辑老人信息
    updata: function (row) {
      this.orderRenAddshow = true
      console.log(row);
      this.oldmanForm = Object.assign({}, row);
    },
  }
}
</script>

<style scoped>
.maxBox {
  background-color: #f9f9f9;
}
.gridMaxClass {
  width: 95%;
  background: #ffff;
  margin: auto;
  margin-top: 10px;
  border-radius: 5px;
}
.gridClass {
  border-radius: 5px;
  background: #ffff;
  padding: 3px;
}
.header {
  background-image: url('https://lycaljdapi.dexian.ren//upload/images/2021/12/f9ec9395-fc1.png');
  background-size: 100% 50%;
  background-repeat: no-repeat;
}
.personal {
  width: 100%;
  align-items: center;
  text-align: center;
}
.homebanner img {
  width: 100%;
  vertical-align: top;
  margin: auto;
  border-radius: 5px;
}
.homebanner {
  font-size: 14px;
  color: #646566;
}
.tost {
  float: left;
  background: #d81e06;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 7px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 5px;
}
.packages {
  margin: 10px;
  padding: 7px;
  background: #ffc002;
  border-radius: 5px;
}
.packagesName {
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 0 7px 7px 7px;
}
.palace {
  color: #646566;
  font-size: 12px;
  margin-top: 5px;
}
.van-cell {
  background: #ffc002 !important;
  border-bottom: 1px solid #ffc002 !important;
}
.shuru {
  background: white !important;
  border-bottom: 1px solid #f8f5eb !important;
}
.van-ellipsis--l1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
}
.information {
  width: 95%;
  margin: auto;
}
/deep/.van-grid-item__content--center {
  padding: 8px 8px !important;
}
.placeOrder {
  width: 100%;
  text-align: center;
  float: right;
  padding: 2px 5px;
  /* border: 1px solid; */
  border-radius: 10px 0;
  font-size: 18px;
  background-color: #ffc002;
}
.van-action-sheet {
  height: 500px !important;
}
.van-action-sheet__content {
  margin-bottom: 60px !important;
}
.orginBottom {
  width: 95%;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}
/deep/.van-stepper--round .van-stepper__plus {
  background-color: #ffc002 !important;
}
/deep/.van-stepper--round .van-stepper__minus {
  background-color: #ffc002 !important;
  border: 1px solid #ffc002 !important;
  color: #fff !important;
}
/deep/.van-field__control {
  color: white;
}
.hidden {
  margin: 10px 0;
  font-size: 10px;
  color: #ccc;
  text-align: center;
}
.waterfall {
  /* width: 95%; */
  margin: 5px auto;
  column-count: 2;
  column-gap: 0;
  column-width: auto;
}
.waterfall .list {
  margin: 0px 10px 15px 10px;
  break-inside: avoid;
  border-radius: 5px;
  background-color: #ffffff;
  /* box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%); */
}
.list img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* height: 160px; */
  /* border-radius: 0px; */
}
.tagClass {
  margin: 3px 3px 3px 0;
  padding: 2px;
  color: #fe5b47;
  border: 1px solid #ffa296;
  font-size: 14px;
  border-radius: 3px;
}
.grouptitle {
  border-left: 4px solid #ff8f1f;
  /* float: left; */
  padding-left: 5px;
  margin: 5px;
}
.money {
  color: #f2270c;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.van-ellipsis--l1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
}
.orderYx {
  float: left;
  margin: 5px 10px;
  border-bottom: 1px solid #c6c6c6;
  width: 95%;
  padding-bottom: 5px;
}
.orderRight {
  float: left;
  margin-left: 15px;
  font-size: 14px;
  color: #646566;
  width: 70%;
}
</style>